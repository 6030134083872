var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticStyle:{"max-width":"600px"},model:{value:(_vm.planForm),callback:function ($$v) {_vm.planForm=$$v},expression:"planForm"}},[(
      this.userData.hasContractor == 'no' &&
      this.userData.cantBenf == 'Titular'
    )?_c('div',{staticClass:"text-center"},[_vm._v(" Datos Contratante Plan Titular "),_c('Insured',{attrs:{"userData":_vm.userData}})],1):_vm._e(),(
      this.userData.cantBenf != 'Titular' &&
      this.userData.hasContractor == 'no'
    )?_c('div',{staticClass:"text-center"},[_vm._v(" Datos Contratante Plan Titular + "),_c('Insured',{attrs:{"userData":_vm.userData}}),_vm._l((_vm.beneficiaries),function(beneficiary){return _c('Beneficiary',{key:beneficiary.id,attrs:{"beneficiaryData":beneficiary}})}),_c('div',{staticClass:"add-beneficiary",attrs:{"id":"myDIV"},on:{"click":_vm.addBeneficiary}},[_c('div',{staticClass:"add-icon"},[_c('img',{attrs:{"src":_vm.addIcon}})]),_c('div',{staticClass:"add-label"},[_vm._v("Añadir otro beneficiario")])]),_c('v-form',{staticStyle:{"max-width":"600px","margin-top":"2rem"}},[_c('h2',[_vm._v("Beneficiarios")]),_c('v-tabs',{attrs:{"color":"#464E71"}},[_vm._l((_vm.beneficiaries),function(beneficiary){return _c('v-tab',{key:beneficiary.id},[_vm._v("Beneficiario "+_vm._s(beneficiary.id))])}),_vm._l((_vm.beneficiaries),function(beneficiary){return _c('v-tab-item',{key:beneficiary.id},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"b-label"},[_vm._v("RUT")]),_c('div',{staticClass:"b-value"},[_vm._v(_vm._s(beneficiary.rut))])]),_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"b-label"},[_vm._v("Parentesco")]),_c('div',{staticClass:"b-value"},[_vm._v(_vm._s(beneficiary.type))])]),_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"b-label"},[_vm._v("Fecha de Nacimiento")]),_c('div',{staticClass:"b-value"},[_vm._v(_vm._s(beneficiary.birthdate))])])])],1)})],2)],1)],2):_vm._e(),(
      this.userData.cantBenf != 'Titular' &&
      this.userData.hasContractor == 'null'
    )?_c('div',{staticClass:"text-center"},[_vm._v(" Datos de Beneficiarios "),_vm._l((_vm.beneficiaries),function(beneficiary){return _c('Beneficiary',{key:beneficiary.id,attrs:{"beneficiaryData":beneficiary}})}),_c('div',{staticClass:"add-beneficiary",attrs:{"id":"myDIV"},on:{"click":_vm.addBeneficiary}},[_c('div',{staticClass:"add-icon"},[_c('img',{attrs:{"src":_vm.addIcon}})]),_c('div',{staticClass:"add-label"},[_vm._v("Añadir otro beneficiario")])]),_c('v-form',{staticStyle:{"max-width":"600px","margin-top":"2rem"}},[_c('h2',[_vm._v("Beneficiarios")]),_c('v-tabs',{attrs:{"color":"#464E71"}},[_vm._l((_vm.beneficiaries),function(beneficiary){return _c('v-tab',{key:beneficiary.id},[_vm._v("Beneficiario "+_vm._s(beneficiary.id))])}),_vm._l((_vm.beneficiaries),function(beneficiary){return _c('v-tab-item',{key:beneficiary.id},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"b-label"},[_vm._v("RUT")]),_c('div',{staticClass:"b-value"},[_vm._v(_vm._s(beneficiary.rut))])]),_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"b-label"},[_vm._v("Parentesco")]),_c('div',{staticClass:"b-value"},[_vm._v(_vm._s(beneficiary.type))])]),_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"b-label"},[_vm._v("Fecha de Nacimiento")]),_c('div',{staticClass:"b-value"},[_vm._v(_vm._s(beneficiary.birthdate))])])])],1)})],2)],1)],2):_vm._e(),_c('div',{staticClass:"button-container"},[_c('v-btn',{attrs:{"color":"#E8C665","elevation":"0"},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" Siguiente ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }