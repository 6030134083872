<template>
  <div class="step-data">
    <h1>“Con qué género te identificas?”</h1>
    <v-form
      ref="form"
      v-model="userData.gender"
      :rules="genderRules"
      required
      style="max-width: 600px"
    >
      <div class="gender-selector">
        <div
          v-for="option in genderOptions"
          :key="option.id"
          :class="
            userData.gender == option.id
              ? 'gender-option active'
              : 'gender-option'
          "
          @click="selectGender(option.id)"
        >
          <img class="gender-image" :src="option.image" />
          <span class="gender-label">{{ option.label }}</span>
        </div>
      </div>
      <div class="button-container">
        <v-btn color="#E8C665" elevation="0" @click="validate()">
          Siguiente
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import maleImage from "@/assets/male.svg";
import femaleImage from "@/assets/female.svg";
import otherImage from "@/assets/other.svg";

export default {
  name: "Step2",
  props: ["userData"],
  data: () => ({
    e1: 2,
    genderOptions: [
      {
        id: "male",
        label: "Masculino",
        image: maleImage,
      },
      {
        id: "female",
        label: "Femenino",
        image: femaleImage,
      },
      {
        id: "other",
        label: "Otro",
        image: otherImage,
      },
    ],
    valid: false,
    genderRules: [(v) => this.validateGender(v) || "Genero no valido"],
  }),
  methods: {
    selectGender(gender) {
      this.userData.gender = gender;
    },
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      } else if (step === 6) {
        this.e1 = 5;
      } else if (step === 7) {
        this.e1 = 6;
      }
    },

    validateGender(v) {
      if (this.userData.gender === true) {
        console.log(v);
        return false;
      } else {
        console.log(v);
        return true;
      }
    },

    SaveDates(dates) {
      this.$refs.menu.save(dates);
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.userData.gender === true) {
          // alert("Género nulo")
          console.log("Género nulo");
        } else {
          this.$emit("next-step");
        }
      }
    },
  },
};
</script>