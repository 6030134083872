<template>
  <v-form ref="form" v-model="planForm" style="max-width: 600px">
    <v-radio-group v-model="userData.dps" :rules="confirmRules" required row>
      <v-radio value="null"
        ><template v-slot:label>
          <div class="forecast-selector">Si</div>
        </template></v-radio
      >
      <v-radio value="no"
        ><template v-slot:label>
          <div class="forecast-selector">No</div>
        </template></v-radio
      >
    </v-radio-group>

    <v-checkbox v-model="userData.agree" :rules="confRules" required
      ><template v-slot:label>
        <div class="agree">
          Acepto los
          <a
            v-if="userData.dps == 'no'"
            href="documents/Condiciones-Oncológico-Walmart.pdf"
            target="_blank"
            >Términos y Condiciones (Descargar Poliza)</a
          >
          <a v-else>Términos y Condiciones</a>
        </div>
      </template></v-checkbox
    >

    <div class="button-container">
      <v-btn color="#E8C665" elevation="0" @click="validate()">
        Siguiente
      </v-btn>
    </div>
  </v-form>
</template>
<script>
export default {
  name: "Step7",
  props: ["userData"],
  data: () => ({
    valid: false,
    confRules: [(v) => !!v || "Debes aceptar para continuar"],
    confirmRules: [(v) => !!v || "Debes seleccionar una opcion"],
  }),

  methods: {
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      } else if (step === 6) {
        this.e1 = 5;
      } else if (step === 7) {
        this.e1 = 6;
      }
    },
    SaveDates(dates) {
      this.$refs.menu.save(dates);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("next-step");
      }
    },
  },
};
</script>