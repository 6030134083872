import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;
const { VUE_APP_SPONSOR } = process.env;

export default class ShieldService {
  static async getPlans(forecast, benef) {
    try {
      //Obtener los parámetros para buscar planes
      var cantBenf;
      var category;
      if (forecast == "isapre" && benef == "Titular") {
        category = "isapre100";
        cantBenf = "Plan Titular 0";
      } else if (forecast == "isapre" && benef == "Titular + 1") {
        category = "isapre100";
        cantBenf = "Plan Titular 1";
      } else if (forecast == "isapre" && benef == "Titular + 2") {
        category = "isapre100";
        cantBenf = "Plan Titular 2";
      } else if (forecast == "isapre" && benef == "Titular + 3 o más") {
        category = "isapre100";
        cantBenf = "Plan Titular 3";
      } else if (forecast == "fonasa" && benef == "Titular") {
        category = "fonasa100";
        cantBenf = "Plan Titular 0";
      } else if (forecast == "fonasa" && benef == "Titular + 1") {
        category = "fonasa100";
        cantBenf = "Plan Titular 1";
      } else if (forecast == "fonasa" && benef == "Titular + 2") {
        category = "fonasa100";
        cantBenf = "Plan Titular 2";
      } else if (forecast == "fonasa" && benef == "Titular + 3 o más") {
        category = "fonasa100";
        cantBenf = "Plan Titular 3";
      }

      //Llamada al controlador oncologico en SHIELD, para la busqueda de planes
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/oncologico?sponsor=${VUE_APP_SPONSOR}&category=${category}&cantBenf=${cantBenf}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, plan) {
    //En caso de que datos Contratante no sea igual a datos Asegurados, se almacena la ID de comuna y region en insuredData
    if (person.hasContractor == "no") {
      person.insuredData.region = person.insuredData.region.id;
      person.insuredData.comune = person.insuredData.comune.id;
    }

    //Almacenar ID de region y comuna para formato instanda/ Agregar campo birthdate en person
    person.region = person.region.id;
    person.commune = person.commune.id;
    person.birthdate = person.dates;

    //Generar Array ordenado de Beneficiarios
    var benf = [];
    person.beneficiaries.forEach((element) => {
      benf.push({
        rut: element.rut,
        parentesco: element.type,
        fechaNacimiento: element.birthdate,
      });
    });
    person.BenefData = benf;

    //Se genera la petición a SIELD para generar la Insurance y conección a Instanda
    const jsonData = { person, planId: plan };
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/oncologico`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }
}
