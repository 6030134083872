 <template>
  <div class="step-data">
    <h1>Cuéntanos de ti!</h1>
    <v-form ref="form" v-model="valid" style="max-width: 600px">
      <v-text-field
        v-model="userData.name"
        :rules="nameRules"
        label="Nombres"
        required
        filled
        flat
        solo
        background-color="#eef3f8"
      ></v-text-field>

      <v-text-field
        v-model="userData.surname"
        :rules="surnameRules"
        label="Apellidos"
        required
        filled
        flat
        solo
        background-color="#eef3f8"
      ></v-text-field>

      <h2 class="text-center">Fecha de Nacimiento</h2>

      <div class="step-date" style="max-width: 300px" >

      <v-select
        :items="days"
        v-model="day"
        :loading="loadingDays"
        :disabled="loadingDays || month === null"
        item-text="name"
        label="Día"
        required
        filled
        flat
        solo
        return-object
        background-color="#eef3f8"
      ></v-select>

      <v-select
        :items="months"
        v-model="month"
        :loading="loadingMonths"
        v-on:change="getDays()"
        item-text="name"
        label="Mes"
        required
        filled
        flat
        solo
        return-object
        background-color="#eef3f8"
      ></v-select>

      <v-select
        value="2022"
        :items="years"
        v-model="year"
        item-text="name"
        label="Año"
        required
        filled
        flat
        solo
        return-object
        background-color="#eef3f8"
      ></v-select>

      </div>      

      <h2 class="text-center">Beneficiarios</h2>

      <v-select 
        :items="benf"
        v-model="userData.cantBenf"
        :rules="benefRules"
        label="Cantidad de Beneficiarios"
        required
        filled
        flat
        solo
        background-color="#eef3f8"
        item-text="label"
      ></v-select>

      <h2 class="text-center">¿Cuál es tu sistema de salud?</h2>
      <v-radio-group
        v-model="userData.forecast"
        :rules="saludRules"
        required
        row
      >
        <v-radio value="isapre" label="Isapre"> </v-radio>
        <v-radio value="fonasa" label="Fonasa"> </v-radio>
      </v-radio-group>

      <div class="button-container">
        <v-btn color="#E8C665" @click="validate()"> Continuar </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";

export default {
  name: "Step1",
  props: ["userData", "e1"],
  data: () => ({
    month: null,
    day: null,
    year: null,
    months: [],
    days: [],
    yars: [],
    benf: ["Titular", "Titular + 1", "Titular + 2", "Titular + 3 o más"],
    valid: false,
    nameRules: [(v) => !!v || "El nombre es requerido"],
    surnameRules: [(v) => !!v || "El nombre es requerido"],
    benefRules: [(v) => !!v || "Seleccione Beneficiarios"],
    birthRules: [(v) => !!v || "Seleccione Fecha de Nacimiento"],
    saludRules: [(v) => !!v || "Seleccione Una Opcion"],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
  }),
  mounted() {
    this.getMonths();
    this.getYears();
  },
  methods: {
    SaveDates(dates) {
      this.$refs.menu.save(dates);
    },
    validate() {
      // this.$emit("next-step");

      var newDate = this.year.id + "-" + this.month.number + "-" + this.day.id
      this.userData.dates = newDate

      if (this.$refs.form.validate()) {
        this.$emit("next-step");
      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.month) {
        this.loadingDays = true;
        this.day = null;
        this.days = await dateService.getDays(
          this.month.name
        );
        this.loadingDays = false;

      }
    },
    async getYears(){
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
  },
};
</script>

<style lang="scss">

.step-date{
    align-items: center;
    display: flex;
    margin-left: 0rem;
}
</style>