<template>
  <div>
    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Género </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3"> Plan </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4"> Datos </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 5" step="5">
          Contratante
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 6" step="6">
          Beneficiarios
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="7"> Validación </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-card class="mb-12" min-height="200px" elevation="0">
          <v-stepper-content step="1">
            <div v-if="loading" class="text-center">
              <v-progress-circular
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <br /><br />{{ cont }}
            </div>
            <Step1 v-else :userData="userData" @next-step="nextStep(1)" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <div v-if="loading" class="text-center">
              <v-progress-circular
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else class="back-container">
              <v-btn class="back-button" text @click="e1--">
                <v-icon left> mdi-chevron-left </v-icon> Atrás
              </v-btn>
            </div>
            <Step2 :userData="userData" @next-step="nextStep(2)" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <div class="step-data">
              <div class="back-container">
                <v-btn class="back-button" text @click="back(3)">
                  <v-icon left> mdi-chevron-left </v-icon> Atrás
                </v-btn>
              </div>
              <h1>“Este es el plan que más se acomoda a ti”</h1>
              <v-form
                ref="form"
                v-model="planForm"
                style="display: flex; flex-wrap: wrap; justify-content: center"
              >
                <Plan
                  v-for="plan in plans"
                  :key="plan.id"
                  :planData="plan"
                  @next-step="selectPlan(plan)"
                />
              </v-form>
            </div>
          </v-stepper-content>

          <v-stepper-content step="4">
            <div v-if="loading" class="text-center">
              <v-progress-circular
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else class="back-container">
              <v-btn class="back-button" text @click="back(4)">
                <v-icon left> mdi-chevron-left </v-icon> Atrás
              </v-btn>
            </div>
            <Step4 :userData="userData" @next-step="nextStep(4)" />
          </v-stepper-content>

          <v-stepper-content step="5">
            <div class="step-data">
              <div class="back-container">
                <v-btn class="back-button" text @click="back(5)">
                  <v-icon left> mdi-chevron-left </v-icon> Atrás
                </v-btn>
              </div>
              <div style="max-width: 500px">
                <h1>
                  “Los datos de la persona asegurada son los mismos del
                  contratante?”
                </h1>
              </div>
              <Step5 :userData="userData" @next-step="nextStep(5)" />
            </div>
          </v-stepper-content>

          <v-stepper-content step="6">
            <div class="step-data">
              <div class="back-container">
                <v-btn class="back-button" text @click="back(6)">
                  <v-icon left> mdi-chevron-left </v-icon> Atrás
                </v-btn>
              </div>
              <h1>“Me puedes indicar los datos del contratante?”</h1>
              <Step6 :userData="userData" @next-step="nextStep(6)" />
            </div>
          </v-stepper-content>

          <v-stepper-content step="7">
            <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular
                :size="100"
                color="primary"
                indeterminate
                :label="cont"
              ></v-progress-circular>
              <br /><br />{{ cont }}
            </div>

            <div v-else-if="!loading && !loadingFinal" class="step-data">
              <div class="back-container">
                <v-btn class="back-button" text @click="back(7)">
                  <v-icon left> mdi-chevron-left </v-icon> Atrás
                </v-btn>
              </div>
              <h1>“Estás a un paso de contratar tu seguro!”</h1>
              <p style="max-width: 600px">
                Tú o algún otro miembro de tu grupo familiar incorporado en este
                plan ha tenido o tiene conocimiento de padecer o de tener
                diagnosticada alguna dolencia o ha sido sometido a tratamiento
                médico por alguna de las siguientes enfermedades: Cáncer de
                cualquier naturaleza; Hepatitis B o C; Trasplante de organos;
                VIH (Sida)?
              </p>
              <Step7 :userData="userData" @next-step="nextStep(7)" />
            </div>
          </v-stepper-content>
        </v-card>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ucChristusImage from "@/assets/UCCHRISTUS.svg";
import addIcon from "@/assets/profile-add.svg";
import Plan from "@/components/Plan.vue";
import swal from 'sweetalert';

import Step1 from "@/components/Step1.vue";
import Step2 from "@/components/Step2.vue";
import Step4 from "@/components/Step4.vue";
import Step5 from "@/components/Step5.vue";
import Step6 from "@/components/Step6.vue";
import Step7 from "@/components/Step7.vue";

import ShieldService from "@/services/shield.service.js";

export default {
  name: "Quote",
  components: {
    Plan,
    Step1,
    Step2,
    Step4,
    Step5,
    Step6,
    Step7,
  },

  data() {
    return {
      cont: "Cargando.  ",
      e1: 1,
      loading: false,
      loadingFinal: false,
      addIcon,

      userData: {
        email: null,
        rut: null,
        serialNumber: null,
        gender: "",
        address: null,
        region: null,
        commune: null,
        forecast: null,
        dates: null,
        hasContractor: null,
        beneficiaries: [{}],
        dps: "",
        insuredData: {
          name: null,
          rut: null,
          serialNumber: null,
          phoneNumber: null,
          email: null,
          address: null,
          region: null,
          comune: null,
        },
        lastStep: null,
        agree: null,
      },

      newRut: "",
      valid: true,
      planForm: true,
      idForm: true,
      addressForm: true,
      loadingRegions: false,
      loadingCommunes: false,

      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      menu: false,
      plans: [],

      selectedPlan: null,
    };
  },
  methods: {
    async nextStep(step, plan) {
      this.loading = true;
      if (step === 1) {
        const fechaActual = new Date(Date.now());
        const añoActual = fechaActual.getFullYear();
        //console.log(añoActual);
        const fechaNacimiento = new Date(this.userData.dates);
        const añoNacimiento = fechaNacimiento.getFullYear();

        var calculoYear = añoActual - añoNacimiento;

        console.log(calculoYear); //crear funcionalidad que valide dia/mes/año

        if (calculoYear < 18) {
          swal({
            title: "Límite de edad",
            text: "El contratante debe ser mayor a 18 años.",
            icon: "error",
            button: "Ok!",
          });
        } else {
          console.log("es mayor de 18");
          // Parametros shieldservice
          //this.userData.forecast
          //this.userData.cantBenf
          try {
            var effect = this.cont;
            for (var x = 0; x < 5; x++) {
              effect += " . ";

              setTimeout(() => {
                this.cont = effect;
              }, 1000);
            }

            const res = await ShieldService.getPlans(
              this.userData.forecast,
              this.userData.cantBenf
            );
            // Redirigir a Flow
            console.log("Dimension Arreglo: " + Object.keys(this.plans).length);
            console.log(res);
            if (Object.keys(this.plans).length < 1) {
              for (var i = 0; i < 15; i++) {
                this.plans.splice(x);
              }

              this.plans.push({
                id: 1,
                planId: res[0]._id,
                name: res[0].name,
                title: res[0].type,
                description: res[0].coverage[0].description,
                priceCLP: res[0].values[0].priceCLP,
                priceUF: res[0].values[0].price,
                image: ucChristusImage,
              });
            }

            this.cont = "Cargando.  ";
            console.log("Dimension Arreglo: " + Object.keys(this.plans).length);

            this.e1 = 2;
          } catch (error) {
            console.log(error);
            swal({
            title: "No se ha encontrado el plan!",
            text: "Ocurrió un error al momento de traer el plan",
            icon: "error",
            button: "Ok!",
          });

          }
        }
      } else if (step === 2) {
        this.planData = plan;
        this.e1 = 3;
      } else if (step === 3) {
        this.planData = plan;
        this.e1 = 4;
      } else if (step === 4) {
        this.planData = plan;
        this.e1 = 5;
      } else if (step === 5) {
        this.planData = plan;
        console.log(this.userData.hasContractor);
        console.log("_" + this.userData.cantBenf + "_");
        var sw;
        if (
          this.userData.hasContractor != "no" &&
          this.userData.cantBenf == "Titular"
        ) {
          sw = 7;
        } else {
          sw = 6;
        }
        console.log(sw);
        this.e1 = sw;
      } else if (step === 6) {
        this.planData = plan;
        this.e1 = 7;
      } else if (step === 7) {
        if (this.userData.dps == "null") {
          swal({
            title: "Precaución!",
            text: "Si Tú o algún otro miembro de tu grupo familiar incorporado en este plan ha tenido o padece de alguno de los diagnosticos mencionados anteriormente, no podrás contratar el plan",
            icon: "warning",
            button: "Ok!",
          });

        } else {
          swal({
            title: "Formulario completado!",
            text: "Redirigiendo al pago online",
            icon: "success",
            button: "Ok!",
          });
          // Conectar con SHIELD
          console.log(this.userData);
          this.loadingFinal = true;
          this.cont = "Estamos Procesando tu Solicitud . . .";
          try {
            const res = await ShieldService.createInsurance(
              this.userData,
              this.plans[0].planId
            );
            // Redirigir a Flow

            window.location.href = res;
          } catch (error) {
            this.loadingFinal = false;
            this.loading = false;
            swal({
              // title: "Error!",
              text: "Verifica que tus datos sean correctos",
              icon: "error",
              button: "Ok!",
            });
            console.log(this.userData);
            console.log(this.plans[0].planId);
          }
        }
      }
      this.loading = false;
    },
    back(step) {
      if (step === 2) {
        this.e1 = 1;

        console.log("Dimension Arreglo: " + Object.keys(this.plans).length);
      } else if (step === 3) {
        console.log("Dimension Arreglo: " + Object.keys(this.plans).length);
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      } else if (step === 6) {
        console.log(this.userData.hasContractor);
        this.e1 = 5;
      } else if (step === 7) {
        console.log(this.userData.hasContractor);
        var sw;
        if (
          this.userData.hasContractor != "no" &&
          this.userData.cantBenf == "Titular"
        ) {
          sw = 5;
        } else {
          sw = 6;
        }
        this.e1 = sw;
      }
    },
    //this.loading = false;

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
      //console.log(this.selectedPlan);
      this.e1 = this.e1 + 1;
    },
    ShowUserData() {
      console.log(this.userData);
    },
  },
};
</script>

<style lang="scss">
.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 500;
    text-align: center;
    color: #464e71;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 500;
    color: #464e71;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
  }

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {
      &:hover {
        opacity: 1;
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}

.v-btn__content {
  padding: 0.75rem;
}

.gender-selector {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;

  .gender-option {
    background-color: #eef3f8;
    border: 1px solid #eef3f8;
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.25;
    width: 135px;
    // height: 65px;
    cursor: pointer;
    border-radius: 12px;
    padding: 1rem;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: #d4e2ed;
      border: 1px solid #d4e2ed;
    }

    &.active {
      border: 1px solid #2c6ea9;
    }
  }
}

.v-input--radio-group__input {
  justify-content: center;
}

.add-beneficiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  .add-icon {
    border: 1px solid #2c6ea9;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .add-label {
    margin-left: 1rem;
    color: #444444;
    // opacity: 0.5;
  }

  &:hover {
    opacity: 0.5;
  }
}

.b-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .b-label {
    color: #444444;
    opacity: 0.75;
  }

  .b-value {
    color: #444444;
  }
}
</style>