<template>
  <v-form ref="form" v-model="planForm" style="max-width: 600px">
    <div
      v-if="
        this.userData.hasContractor == 'no' &&
        this.userData.cantBenf == 'Titular'
      "
      class="text-center"
    >
      Datos Contratante Plan Titular
      <Insured :userData="userData" />
    </div>

    <div
      v-if="
        this.userData.cantBenf != 'Titular' &&
        this.userData.hasContractor == 'no'
      "
      class="text-center"
    >
      Datos Contratante Plan Titular +

      <Insured :userData="userData" />

      <Beneficiary
        v-for="beneficiary in beneficiaries"
        :key="beneficiary.id"
        :beneficiaryData="beneficiary"
      />
      <div class="add-beneficiary" id="myDIV" @click="addBeneficiary">
        <div class="add-icon">
          <img :src="addIcon" />
        </div>
        <div class="add-label">Añadir otro beneficiario</div>
      </div>
      <v-form style="max-width: 600px; margin-top: 2rem">
        <h2>Beneficiarios</h2>
        <v-tabs color="#464E71">
          <v-tab v-for="beneficiary in beneficiaries" :key="beneficiary.id"
            >Beneficiario {{ beneficiary.id }}</v-tab
          >
          <v-tab-item
            v-for="beneficiary in beneficiaries"
            :key="beneficiary.id"
          >
            <v-container fluid>
              <div class="b-container">
                <div class="b-label">RUT</div>
                <div class="b-value">{{ beneficiary.rut }}</div>
              </div>
              <div class="b-container">
                <div class="b-label">Parentesco</div>
                <div class="b-value">{{ beneficiary.type }}</div>
              </div>
              <div class="b-container">
                <div class="b-label">Fecha de Nacimiento</div>
                <div class="b-value">{{ beneficiary.birthdate }}</div>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-form>
    </div>

    <div
      v-if="
        this.userData.cantBenf != 'Titular' &&
        this.userData.hasContractor == 'null'
      "
      class="text-center"
    >
      Datos de Beneficiarios

      <Beneficiary
        v-for="beneficiary in beneficiaries"
        :key="beneficiary.id"
        :beneficiaryData="beneficiary"
      />
      <div class="add-beneficiary" id="myDIV" @click="addBeneficiary">
        <div class="add-icon">
          <img :src="addIcon" />
        </div>
        <div class="add-label">Añadir otro beneficiario</div>
      </div>
      <v-form style="max-width: 600px; margin-top: 2rem">
        <h2>Beneficiarios</h2>
        <v-tabs color="#464E71">
          <v-tab v-for="beneficiary in beneficiaries" :key="beneficiary.id"
            >Beneficiario {{ beneficiary.id }}</v-tab
          >
          <v-tab-item
            v-for="beneficiary in beneficiaries"
            :key="beneficiary.id"
          >
            <v-container fluid>
              <div class="b-container">
                <div class="b-label">RUT</div>
                <div class="b-value">{{ beneficiary.rut }}</div>
              </div>
              <div class="b-container">
                <div class="b-label">Parentesco</div>
                <div class="b-value">{{ beneficiary.type }}</div>
              </div>
              <div class="b-container">
                <div class="b-label">Fecha de Nacimiento</div>
                <div class="b-value">{{ beneficiary.birthdate }}</div>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-form>
    </div>

    <div class="button-container">
      <v-btn color="#E8C665" elevation="0" @click="validate()">
        Siguiente
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import Insured from "@/components/Insured.vue";
import Beneficiary from "@/components/Beneficiary.vue";
import RutUtils from "../utils/rut.utils";
import swal from 'sweetalert';

export default {
  name: "Step6",
  props: ["userData"],
  components: {
    Beneficiary,
    Insured,
  },
  data: () => ({
    asegurado: false,
    valid: false,
    confirmRules: [(v) => !!v || "Rellena el campo obligatorio"],
    rutRules: [
      (v) => !!v || "El RUT del beneficiario es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    beneficiaries: [{}],
    newRut: "",
  }),

  methods: {
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      } else if (step === 6) {
        this.e1 = 5;
      } else if (step === 7) {
        this.e1 = 6;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        var bol = false;
        var sw = false;
        var cantidad;

        if (this.userData.hasContractor == "no") {
          var campo = false;
          var formulario = false;

          //Validación formulario de Asegurado
          if (this.userData.insuredData.name != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.rut != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.serialNumber != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.phoneNumber != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.email != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.address != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.region != null) {
            campo = true;
          } else {
            campo = false;
          }
          if (this.userData.insuredData.comune != null) {
            campo = true;
          } else {
            campo = false;
          }

          if (campo == true) {
            formulario = true;
          } else {
            formulario = false;
            swal({
            // title: "Error!",
            text: "Complete todos los campos de Asegurado",
            icon: "info",
            button: "Ok!",
          });
          }
        } else {
          formulario = true;
        }

        //Validación formulario de Beneficiarios
        this.userData.beneficiaries.splice(0);
        this.beneficiaries.forEach((benef) => {
          if (benef.rut != null) {
            console.log("1");
            bol = true;
          } else {
            bol = false;
          }
          if (benef.type != null) {
            console.log("2");
            bol = true;
          } else {
            bol = false;
          }
          if (benef.birthdate != null) {
            console.log("3");
            bol = true;
          } else {
            bol = false;
          }

          if (this.userData.cantBenf == "Titular") {
            bol = true;
          }

          if (bol == true) {
            console.log("4");
            sw = true;
          } else {
            sw = false;
          }

          this.userData.beneficiaries.push(benef); //cambiar BenefData
        });

        if (sw == false) {
          swal({
            title: "Falta información!",
            text: "Complete todos los campos de Beneficiario",
            icon: "info",
            button: "Ok!",
          });
        }

        //Validación de cantidad de beneficiarios y Asegurado creados
        if (this.userData.cantBenf == "Titular" && formulario == true) {
          bol = true;
        } else if (
          this.userData.cantBenf == "Titular + 1" &&
          this.beneficiaries.length == 1 &&
          sw == true &&
          formulario == true
        ) {
          if (this.beneficiaries.length == 1) {
            bol = true;
          } else {
            bol = false;
          }
        } else if (
          this.userData.cantBenf == "Titular + 2" &&
          this.beneficiaries.length == 2 &&
          sw == true &&
          formulario == true
        ) {
          if (this.beneficiaries.length == 2) {
            bol = true;
          } else {
            bol = false;
          }
        } else if (
          this.userData.cantBenf == "Titular + 3 o más" &&
          this.beneficiaries.length >= 3 &&
          sw == true &&
          formulario == true
        ) {
          if (this.beneficiaries.length >= 3) {
            bol = true;
          } else {
            bol = false;
          }
        } else {
          bol = false;
        }

        if (bol == true) {
          console.log("ESTA OK");
          this.$emit("next-step");
        } else {
          if (
            this.userData.cantBenf == "Titular + 3 o más" &&
            this.beneficiaries.length < 3
          ) {
            cantidad = "3 o más Beneficiarios";
            swal({
              title: "Faltan Beneficiarios!",
              text: "La cantidad de beneficiarios no cuadra. Ingrese " + cantidad + ".",
              icon: "info",
              button: "Ok!",
            });
          } else if (
            this.userData.cantBenf == "Titular + 1" &&
            this.beneficiaries.length < 1
          ) {
            cantidad = "1 Beneficiario";
            swal({
              title: "Faltan Beneficiarios!",
              text: "La cantidad de beneficiarios no cuadra. Ingrese " + cantidad + ".",
              icon: "info",
              button: "Ok!",
            });
          } else if (
            this.userData.cantBenf == "Titular + 2" &&
            this.beneficiaries.length < 2
          ) {
            cantidad = "2 Beneficiarios";
            swal({
              title: "Faltan Beneficiarios!",
              text: "La cantidad de beneficiarios no cuadra. Ingrese " + cantidad + ".",
              icon: "info",
              button: "Ok!",
            });
          }
        }
      }
    },

    addBeneficiary() {
      if (
        this.beneficiaries.length == 1 &&
        this.userData.cantBenf == "Titular + 1"
      ) {
        swal({
          title: "Límite de Beneficiarios!",
          text: "El Plan no permite agregar más beneficiarios",
          icon: "info",
          button: "Ok!",
        });
      } else if (
        this.beneficiaries.length == 2 &&
        this.userData.cantBenf == "Titular + 2"
      ) {
        swal({
          title: "Límite de Beneficiarios!",
          text: "El Plan no permite agregar más beneficiarios",
          icon: "info",
          button: "Ok!",
        });
      } else {
        this.beneficiaries.push({
          id: this.beneficiaries.length + 1,
          rut: null,
          type: null,
          birthdate: null,
        });
      }
    },
  },
};
</script>