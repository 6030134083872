<template>
    <div class="step-data">
        <v-form
            ref="form"
            v-model="idForm"
            style="max-width: 600px;"
        >
        <v-text-field
            v-model="userData.insuredData.name"
            :rules="addressRules"
            label="Nombre Completo"
            required
            filled
            flat
            solo
            background-color="#eef3f8"
        ></v-text-field>

        <v-text-field
            v-model.lazy="newRut"
            :rules="rutRules"
            label="RUT"
            required
            filled
            flat
            solo
            background-color="#eef3f8"
            @input="changeRut"
        ></v-text-field>
 
        <v-text-field
            v-model="userData.insuredData.serialNumber"
            :rules="serialNumberRules"
            label="Nº de Documento/Serie carnet de identidad"
            required
            filled
            flat
            solo
            background-color="#eef3f8"
        ></v-text-field>

        <v-text-field
            v-model="userData.insuredData.phoneNumber"
            :rules="phoneRules"
            :counter="9"
            prefix="+56"
            label="Telefono"
            required
            filled
            flat
            solo
            background-color="#eef3f8"
        ></v-text-field>

        <v-text-field
            v-model="userData.insuredData.email"
            :rules="emailRules"
            label="Correo electrónico"
            required
            filled
            flat
            solo
            background-color="#eef3f8"
        ></v-text-field>

        <v-text-field
            v-model="userData.insuredData.address"
            :rules="addressRules"
            label="Direccion"
            required
            filled
            flat
            solo
            background-color="#eef3f8"
        ></v-text-field>

        <v-select
            :items="regions"
            v-model="userData.insuredData.region"
            :rules="regionRules"
            :loading="loadingRegions"
            v-on:change="getCommunes()"
            item-text="name"
            label="Region"
            required
            filled
            flat
            solo
            return-object
            background-color="#eef3f8"
        ></v-select>

        <v-select
            :items="communes"
            v-model="userData.insuredData.comune"
            :rules="communeRules"
            :loading="loadingCommunes"
            :disabled="loadingCommunes || userData.insuredData.region === null"
            item-text="name"
            label="Comuna"
            required
            filled
            flat
            solo
            return-object
            background-color="#eef3f8"
        ></v-select>

        <!-- <div class="button-container">
            <v-btn color="#E8C665" elevation="0" @click="validate()">
                Siguiente
            </v-btn>
        </div> -->
        
    </v-form>
</div>
</template>

<script>

import CountryService from "@/services/country.service.js";
import RutUtils from "@/utils/rut.utils.js";

export default {
  name: "Insured",
  props: ["userData"],
  data: () => ({
    valid: false,
    addressRules: [(v) => !!v || "Debes ingresar tu direccion"],
    regionRules: [(v) => !!v || "Ingresa la region en la que vives con tu mascota",],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives",],
    rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => RutUtils.validateRut(v) || "Rut no valido",
      ],
      serialNumberRules: [(v) => !!v || "El numero de serie es requerido"],
    emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
      ],
      phoneRules: [
        (v) => !!v || "El número telefónico es requerido",
        (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
        (v) => (!!v && v.length == 9) || "Ingrese un número válido",
      ],
    regions: [],
    communes: [],
    newRut: "",
    
  }),
  mounted() {
    this.getRegions();
  },
  methods: {
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.insuredData.region) {
        this.loadingCommunes = true;
        this.userData.insuredData.comune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.insuredData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.insuredData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.insuredData.rut = this.newRut;
    },

     selectGender(gender) {
      this.userData.insuredData.gender = gender;
    },

    validateGender(v) {
      if (this.userData.insuredData.gender === true){
          console.log(v)
          return false
      } else {
          console.log(v)
          return true;
      }
    },

    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      } else if (step === 6) {
        this.e1 = 5;
      } else if (step === 7) {
        this.e1 = 6;
      }
    },


    SaveDates(dates){
        this.$refs.menu.save(dates)
    },
    validate() {
      if (this.$refs.form.validate()) {
        
            this.$emit("next-step");
    
      }
    },
  },
};
</script>