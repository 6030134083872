<template>
  <v-form style="max-width: 600px;">
    <h2>Beneficiario {{ beneficiaryData.id }}</h2>
    <v-text-field
      v-model.lazy="newRut"
      :rules="rutRules"
      label="RUT"
      required
      filled
      flat
      solo
      return-object
      background-color="#eef3f8"
      @input="changeRut"
    ></v-text-field>
    <v-select
      :items="types"
      v-model="beneficiaryData.type"
      item-text="name"
      label="Parentesco"
      required
      filled
      flat
      solo
      return-object
      background-color="#eef3f8"
    ></v-select>

    <h2 class="text-center">Fecha de Nacimiento</h2>

    <div class="step-date">

      <v-select @input="newDate()"
        :items="days"
        v-model="dayBenef"
        :loading="loadingDays"
        :disabled="loadingDays || monthBenef === null"
        item-text="name"
        label="Día"
        required
        filled
        flat
        solo
        return-object
        background-color="#eef3f8"
      ></v-select>

      <v-select @input="newDate()"
        :items="months"
        v-model="monthBenef"
        :loading="loadingMonths"
        v-on:change="getDays()"
        item-text="name"
        label="Mes"
        required
        filled
        flat
        solo
        return-object
        background-color="#eef3f8"
      ></v-select>

      <v-select @input="newDate()"
        value="2022"
        :items="years"
        v-model="yearBenef"
        item-text="name"
        label="Año"
        required
        filled
        flat
        solo
        return-object
        background-color="#eef3f8"
      ></v-select>

    </div>

  </v-form>
</template>

<script>
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";

import RutUtils from "../utils/rut.utils";
export default {
  name: "Beneficiary",
  props: ["beneficiaryData"],
  data: () => ({
    monthBenef: null,
    dayBenef: null,
    yearBenef: null,
    months: [],
    days: [],
    yars: [],
    types: ["Hijo(a)", "Cónyuge", "Pareja"],
    newRut: "",
    rutRules: [
      (v) => !!v || "El RUT del beneficiario es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    menu: false,
  }),
  mounted() {
    this.getMonths();
    this.getYears();
  },
  methods: {
    changeRut(rut) {
      this.beneficiaryData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.monthBenef) {
        this.loadingDays = true;
        this.dayBenef = null;
        this.days = await dateService.getDays(
          this.monthBenef.name
        );
        this.loadingDays = false;

      }
    },
    async getYears(){
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    newDate() {
      if(this.monthBenef != null && this.dayBenef != null && this.yearBenef != null){
        var newDate = this.yearBenef.id + "-" + this.monthBenef.number + "-" + this.dayBenef.id
        this.beneficiaryData.birthdate = newDate

      }
    }
  },
};
</script>

<style lang="scss">

.step-date{
    max-width: 300px;
    align-items: center;
    display: flex;
    margin-left: 0rem;
}
</style>