<template>
     <v-form
                ref="form"
                v-model="addressForm" 
                style="max-width: 600px;"
              >
               <v-radio-group v-model="userData.hasContractor" :rules="confirmRules" required row>
                  <v-radio value= null
                    ><template v-slot:label>
                      <div class="forecast-selector">Si</div>
                    </template></v-radio
                  >
                  <v-radio value="no" 
                    ><template v-slot:label>
                      <div class="forecast-selector">No</div>
                    </template></v-radio
                  >
                </v-radio-group>
                <div class="button-container">
                  <v-btn color="#E8C665" elevation="0" @click="validate()">
                    Siguiente
                  </v-btn>
                </div>
              </v-form>
</template>

<script>



export default {
  name: "Step5",
  props: ["userData"],
  data: () => ({
    
    valid: false,
    confirmRules: [(v) => !!v || "Selecciona una Opcion"],

    
  }),
  methods: {
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      } else if (step === 6) {
        this.e1 = 5;
      } else if (step === 7) {
        this.e1 = 6;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        
            this.$emit("next-step");
    
      }
    },
  },
};
</script>